/** Vendor */
import DOMPurify from "dompurify";
import axios from "axios";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

/** Redux Store Access */
import store from "@redux/configureStore";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type {
  IBasicRecord,
  IMember,
  IMetadata,
  INotification,
  IStringObject,
} from "types";

dayjs.extend(customParseFormat);

const _audioNoCache = `${dayjs().format("YYYYMMDD")}${new Date().getTime()}`;

export const antTableKeySort = (key: string) => (a: any, b: any) => {
  let c = a[key];
  let d = b[key];
  if (key.includes(".")) {
    const parts = key.split(".");
    c = a[parts[0]][parts[1]];
    d = b[parts[0]][parts[1]];
  }

  const nameA = String(c).toUpperCase(); // ignore upper and lowercase
  const nameB = String(d).toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const searchPattern = /[^a-zA-Z0-9/s.@ -]+/;

export function blobToLocalUri(blob: Blob | void): string {
  if (!blob) return "";
  return URL.createObjectURL(blob);
}

export function buildSelectOptions(
  metadatalist: IBasicRecord[],
  overwrite?: string
) {
  return metadatalist.map((o: any) => ({
    key: o.resource_name,
    label: overwrite ? o[overwrite] : o.name,
    value: o.resource_name,
  }));
}

export function formatCellphone(cellphone: number): string | null {
  const cleaned = String(cellphone).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function filterByText<T>(arr: T[], str = ""): T[] {
  return arr.filter((r: T) => {
    return JSON.stringify(r).toLowerCase().indexOf(str.toLowerCase()) > -1;
  });
}

export function formatFileName(str: string): string {
  return str.replace(/[^a-zA-Z0-9.]/g, "-").toLowerCase();
}

export function formatName(props = {} as IMember) {
  const { rank } = store.getState().metadata;
  const parts = [];
  /** Need to cutover to string version only on metadata */
  if (props?.rank) {
    parts.push(
      rank.list.find((rank: IMetadata<ResourceId.Rank>) => {
        return rank.resource_name === props.rank;
      })?.short_name
    );
  }

  /** Member First and Last Name or Fallback Cases for Invited Members */
  if (props?.given_name) parts.push(toTitleCase(props.given_name));
  if (props?.family_name) parts.push(toTitleCase(props.family_name));
  if (parts.length === 0 && props.nipr_email) return props.nipr_email;
  if (parts.length === 0 && props.email) return props.email;
  if (parts.length === 0 && props.cellphone) {
    return formatCellphone(props.cellphone);
  }
  return parts.join(" ");
}

export async function getWebFile({ size, url }: IStringObject) {
  return axios
    .get(`${url}&size=${size || "original"}`, { responseType: "blob" })
    .catch((e) => e);
}

export function getUrlParams(query = "") {
  const params = new URLSearchParams(query);
  const entries = params.entries();
  const result: IStringObject = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export function keygen(resource_id: ResourceId): string {
  const prefix = resource_id ? `${resource_id}-` : "";
  return (
    prefix +
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
  );
}

export function latestMessageText(notification: INotification): string {
  if (notification.message_type === "audio") return "Audio File";
  if (notification.message_type === "image") return "Image";
  if (notification.message_type === "location") return "Geolocation";
  if (notification.message_type === "text") {
    const data = notification.data?.includes("{")
      ? JSON.parse(notification.data)
      : notification.data;

    return data?.notification?.text || "";
  }
  if (notification.message_type === "video") return "Video File";

  if (notification.message_type === "enhanced") {
    const parsedData = JSON.parse(notification.data);
    const { text } = parsedData.notification;

    if (text && text.includes("<")) {
      const stringFromHTML = String(
        DOMPurify.sanitize(text, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] })
      );
      return stringFromHTML;
    } else {
      return JSON.parse(text).blocks[0].text;
    }
  }
  return "NA";
}

export const liveATCEndpoints = [
  {
    description: "San Diego, CA Intl Airport",
    endpoint: `https://s1-fmt2.liveatc.net/ksan1_twr?nocache=${_audioNoCache}`,
    name: "KSAN Tower 1",
  },
  {
    description: "Miramar MCAS, CA",
    endpoint: `https://s1-bos.liveatc.net/knkx?nocache=${_audioNoCache}`,
    name: "KNKX MCAS",
  },
  {
    description: "Los Angeles, CA Intl Airport",
    endpoint: `https://s1-bos.liveatc.net/klax3?nocache=${_audioNoCache}`,
    name: "KLAX Tower 1",
  },
  {
    description: "March AFB, CA",
    endpoint: `https://s1-fmt2.liveatc.net/kral2?nocache=${_audioNoCache}`,
    name: "KRIV MAFB",
  },
];

export const metadata = {
  findBase: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Base> | undefined => {
    const bases = store.getState().metadata?.base.list;
    return bases.find((base) => {
      return base.resource_name === record.base;
    });
  },
  findBillet: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Billet> | undefined => {
    const billets = store.getState().metadata?.billet.list;
    return billets.find((billet) => {
      return billet.resource_name === record.billet;
    });
  },
  findBuilding: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Building> | undefined => {
    const buildings = store.getState().metadata?.building.list;
    return buildings.find((building) => {
      return building.resource_name === record.building;
    });
  },
  findExercise: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Exercise> | undefined => {
    const exercises = store.getState().metadata?.exercise.list;
    return exercises.find((exercise) => {
      return exercise.resource_name === record.exercise;
    });
  },
  findMos: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.MOS> | undefined => {
    const moss = store.getState().metadata?.mos.list;
    return moss.find((mos) => {
      return mos.resource_name === record.mos;
    });
  },
  findProduct: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Product> | undefined => {
    const products = store.getState().metadata?.product.list;
    return products.find((product) => {
      return product.resource_name === record.product;
    });
  },
  findRank: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Rank> | undefined => {
    const ranks = store.getState().metadata?.rank.list;
    return ranks.find((rank) => rank.resource_name === record.rank);
  },
  findOrganization: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Organization> | undefined => {
    const orgs = store.getState().metadata?.organization.list;
    return orgs.find((org) => org.resource_name === record.organization);
  },
  findSection: <T extends IMember>(
    record: T
  ): IMetadata<ResourceId.Section> | undefined => {
    const sections = store.getState().metadata?.section.list;
    return sections.find((section) => section.resource_name === record.section);
  },
};

export function militaryTime(timestamp: number, format = "HH:mm") {
  return dayjs(dayjs(timestamp || new Date()), "h:mm A").format(format);
}

/** Used within a .filter(notCurrentUser(you)) */
export function notCurrentUser(member: IMember) {
  return (props: IMember): boolean => {
    return props.resource_name !== member.resource_name;
  };
}

export const notificationMedium = [
  { key: "medium-option-0", label: "SMS", value: "sms" },
  { key: "medium-option-1", label: "App", value: "push,websocket" },
  { key: "medium-option-2", label: "DoD Email", value: "nipr-email" },
  { key: "medium-option-3", label: "Personal Email", value: "email" },
  {
    key: "medium-option-4",
    label: "SMS and Personal Email",
    value: "email,sms",
  },
  {
    key: "medium-option-5",
    label: "App, SMS, Personal and DoD Email",
    value: "email,nipr-email,sms",
  },
  {
    key: "medium-option-6",
    label: "All Available Means",
    value: "email,nipr-email,push,sms,websocket",
  },
];

export function removeDuplicates(arr = [], key = "resource_name") {
  key = key ? key : "resource_name";
  return Object.values(
    arr
      .filter((a) => a)
      .reduce((r, props) => {
        if (props[key]) r[props[key]] = props;
        return r;
      }, {})
  );
}

export function sortAToZ(arr = [], field1 = "name") {
  let field2 = "";
  if (field1.includes(".")) {
    const parts = field1.split(".");
    field1 = parts[0];
    field2 = parts[1];
  }

  return arr.sort((a: any, b: any) => {
    /** Handle Nested Object Comparison */
    if (field2) {
      return a[field1][field2].localeCompare(b[field1][field2]);
    }
    return a[field1]?.localeCompare(b[field1]);
  });
}

export function toCleanText(str = ""): string {
  const raw = str;
  return raw.replace(searchPattern, "").trim();
}

export function toTitleCase(str?: string): string {
  if (!str) return "";
  return String(str)
    .replace(/[^a-zA-Z0-9]/g, " ")
    .replace(/\w{3,}/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

//All other users than current one
export function usersOtherThanMe(
  members: IMember[],
  member: IMember
): IMember[] {
  return members.filter((m: IMember) => {
    return m.resource_name !== member.resource_name;
  });
}
