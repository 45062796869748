import { useEffect, useState } from "react";

/** Vendors */
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import {
  Button,
  Flex,
  Popconfirm,
  Result,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { EllipsisOutlined, LineChartOutlined } from "@ant-design/icons";

/** Redux */
import toggleAction from "@redux/actions/toggle";

import {
  createBroadcastAction,
  deleteBroadcastAction,
  getBroadcastAction,
  getBroadcastMetricsAction,
  searchBroadcastsAction,
  sendBroadcastMessageAction,
} from "@redux/actions/broadcast";

/** Custom Hooks */
import useDelay from "@hooks/useDelay";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom Components */
import Header from "./Header";
import Options from "./Options";
import Search from "../../../shared/form/AdvancedSearch";

/** Custom Methods */
import { formatName, toTitleCase } from "@dist/js/support";

/** Enums */
import {
  BroadcastStatus,
  ResourceId,
  Timezone,
  ToggleResource,
  Tool,
} from "types";

/** Types */
import type { ColumnsType } from "antd/lib/table";
import type { IRootState } from "@redux/configureStore";
import type { IBroadcast, IMember, ISearchBroadcasts } from "types";

dayjs.extend(utc);
dayjs.extend(timezone);

function List({ height }: { height: number }) {
  const [loading, setLoading] = useState<boolean>(true);
  const delay = useDelay();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { broadcasts, contacts, dataSetKey, member, templates } =
    useAppSelector((state: IRootState) => ({
      broadcasts: state.broadcast.list,
      contacts: state.contact.list,
      dataSetKey: state.broadcast.dataSetKey,
      member: state.member?.details,
      templates: state.template.list,
    }));

  /** Step 1. Search for broadcasts on load of component */
  useEffect(() => {
    actions.onRefresh();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [dataSetKey]);

  const actions = {
    copy: (broadcast: IBroadcast) => () => {
      const params = {
        ...broadcast,
        delivery_count: 0,
        resource_name: "",
        status: BroadcastStatus.Unsent,
      };
      dispatch(createBroadcastAction(params));
    },
    delete: (broadcast: IBroadcast) => () => {
      dispatch(deleteBroadcastAction(broadcast));
    },
    findMember: (broadcast: IBroadcast) => {
      return [...contacts, member].find((m: IMember) => {
        return m.resource_name === broadcast.author;
      });
    },
    formatMedium: (broadcast: IBroadcast) => {
      const template = templates.find((t) => {
        return t.resource_name === broadcast.template;
      });
      const parts = template?.medium?.split(",") || [];
      if (parts.length > 4) return "All";
      return `${parts.map(toTitleCase).join(", ")}`;
    },
    navigate: (destination: string) => () => {
      navigate(`/apps/broadcast/${destination}`);
    },
    onRefresh: () => {
      setLoading(true);
      dispatch(searchBroadcastsAction({}));
    },
    onSearch: (text: string) => {
      setLoading(true);
      const params = {} as ISearchBroadcasts;
      if (text?.includes("broadcast")) {
        params.broadcasts = [text];
      } else if (text?.includes("organization")) {
        params.organizations = [text];
      } else if (text?.includes("member")) {
        params.authors = [text];
      } else {
        params.text = text;
      }
      delay(() => dispatch(searchBroadcastsAction(params)), 250);
    },
    onSelect: (broadcast: IBroadcast) => () => {
      dispatch(getBroadcastAction(broadcast));

      const { resource_name, status } = broadcast || {};
      if (resource_name && status === BroadcastStatus.Sent) {
        dispatch(getBroadcastMetricsAction(broadcast));
      }
    },
    send: (broadcast: IBroadcast) => () => {
      setLoading(true);
      dispatch(sendBroadcastMessageAction(broadcast));
      delay(() => {
        setLoading(false);
        dispatch(getBroadcastMetricsAction(broadcast));
      }, 2000);
    },
    toggle: (broadcast: Partial<IBroadcast>) => () => {
      /** Step 3. Update the broadcast with the template and open the modal */
      const params1: Partial<IBroadcast> = {
        author: member.resource_name,
        enabled: false,
        channels: [],
        resource_id: ResourceId.Broadcast,
        resource_name: "",
        status: BroadcastStatus.Unsent,
        timezone: Timezone.West,
        title: "Change Me",
        tool: Tool.Broadcast,
        trigger: "manual",
        ...broadcast,
      };

      const params2 = {
        details: params1,
        resource: ToggleResource.BroadcastModal,
      };

      dispatch(toggleAction(params2));
    },
  };

  const columns: ColumnsType<IBroadcast> = [
    {
      dataIndex: "title",
      key: "title",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      title: "Broadcast Title",
    },
    {
      dataIndex: "author",
      filters: contacts.map((contact) => ({
        text: formatName(contact),
        value: contact.resource_name,
      })),
      onFilter: (value, record) => value === record.author,
      key: "author",
      render: (_, broadcast) => formatName(actions.findMember(broadcast)),
      title: "Author",
    },
    {
      dataIndex: "status",
      filters: [
        { text: "Unsent", value: BroadcastStatus.Unsent },
        { text: "Sent", value: BroadcastStatus.Sent },
        { text: "Locked", value: BroadcastStatus.Locked },
      ],
      onFilter: (value, record) => value === record.status,
      key: "status",
      render: (status, broadcast) => {
        if (broadcast.trigger === "timestamp") {
          return (
            <Typography.Text>
              Scheduled @&nbsp;
              {dayjs(broadcast.start)
                .tz(broadcast.timezone || Timezone.West)
                .format("MMM DD, YYYY HH:mm")}
            </Typography.Text>
          );
        }

        if (status === BroadcastStatus.Unsent) {
          return (
            <Popconfirm
              cancelText="Never Mind"
              description="Just double checking before you execute this broadcast"
              okText="Send It"
              onConfirm={actions.send(broadcast)}
              placement="top"
              title="Are You Sure?"
              trigger="click"
            >
              <Button loading={loading} type="primary">
                Start Broadcast
              </Button>
            </Popconfirm>
          );
        }

        if (status === BroadcastStatus.Sent) {
          return (
            <Flex align="center" gap={6}>
              <div style={{ marginRight: 6 }}>
                {broadcast.delivery_count || 0} Contacts Notified
              </div>
              <Tag color="success">Completed</Tag>
            </Flex>
          );
        }
        return <Tag color="caution">Locked</Tag>;
      },
      title: "Status",
      width: 210,
    },
    {
      dataIndex: "options",
      key: "options",
      render: (_, record) => (
        <Flex align="center" gap={6}>
          <Options actions={actions} details={record}>
            <Button
              className="option hoverable broadcast-options"
              icon={<EllipsisOutlined />}
              size="small"
              type="text"
            />
          </Options>
          {record.status === BroadcastStatus.Sent && !record.repeating && (
            <Tooltip title="See Metrics">
              <Button onClick={actions.onSelect(record)} type="text">
                <LineChartOutlined />
              </Button>
            </Tooltip>
          )}
        </Flex>
      ),
      title: "",
      width: 50,
    },
  ];

  return (
    <div className="ims-card broadcast" style={{ maxHeight: height }}>
      <Header
        actions={actions}
        details={{ name: "Step 2. My Broadcasts" }}
        loading={!!loading}
      />
      <div className="header">
        <Search
          active="ims-broadcasts"
          actions={actions}
          data={broadcasts}
          details={{ placeholder: "Search Broadcasts..." }}
          setIsActive={() => {
            console.log("Set Active");
          }}
        />
      </div>
      <div className="body mb-auto p-2">
        <Table
          columns={columns}
          dataSource={broadcasts}
          key={dataSetKey}
          locale={{
            emptyText: (
              <Result
                extra={
                  <Button
                    onClick={actions.toggle({
                      resource_id: ResourceId.Broadcast,
                    })}
                    key="console"
                    type="primary"
                  >
                    Create Broadcast
                  </Button>
                }
                title={
                  <span>
                    Seem's You are just starting out.
                    <br />
                    Select from the list of broadcast templates above
                    <br />
                    or create a new one
                  </span>
                }
              />
            ),
          }}
          rowKey="resource_name"
        />
      </div>
    </div>
  );
}

export default List;
