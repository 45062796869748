/** Vendors */
import { Link } from "react-router-dom";
import { Button, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

/** Redux Setup */
import toggleAction from "@redux/actions/toggle";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom Components */
import FallbackMemberImage from "../image/FallbackMemberImage";
import Search from "./Search";

/** Enums */
import { ToggleResource } from "types";

/** Custom CSS */
import "./navbar.css";

function Navbar() {
  const dispatch = useAppDispatch();
  const { member, show } = useAppSelector((state) => ({
    member: state.member.details,
    show: state.toggle.leftSidebar,
  }));

  const actions = {
    toggle: () => {
      dispatch(
        toggleAction({ details: {}, resource: ToggleResource.LeftSidebar })
      );
    },
  };

  const btnStyle = { height: "100%", width: 70 };

  return (
    <Layout.Header className="p-0 flex flex-row flex-center">
      <Button
        className="menu-btn"
        icon={
          show ? (
            <MenuUnfoldOutlined
              style={{ color: "#ddd", fontSize: 20, width: "inherit" }}
            />
          ) : (
            <MenuFoldOutlined
              style={{ color: "#ddd", fontSize: 20, width: "inherit" }}
            />
          )
        }
        onClick={actions.toggle}
        style={btnStyle}
        title="Open / Close Sidebar"
        type="text"
      />
      <div className="ml-auto" style={{ width: 300 }}>
        <Search />
      </div>
      <div className="menu-btn" style={btnStyle}>
        <Link title="Profile" to="/profile">
          <FallbackMemberImage
            enableClick={false}
            enableDetails={false}
            enableTooltip={false}
            members={[member]}
            width={30}
          />
        </Link>
      </div>
    </Layout.Header>
  );
}

export default Navbar;
