/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";
import type { IAnyObject, IChannel, ISearchChannels } from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

/* Channel Actions */
export function createChannelAction(channel: IChannel) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "channel",
      channel,
      method: "PUT",
      path: "channel",
      type: types.CREATE_CHANNEL_SUCCESS,
    });
  };
}

export function deleteChannelAction(channel: IChannel) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "channel",
      channel,
      method: "DELETE",
      path: "channel",
      type: types.DELETE_CHANNEL_SUCCESS,
    });
  };
}

export function getChannelAction(channel: IChannel) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "channel",
      channel,
      method: "GET",
      path: "channel",
      type: types.GET_CHANNEL_SUCCESS,
    });
  };
}

export function searchChannelsAction(search: ISearchChannels) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "channel",
      method: "GET",
      path: "channel/search",
      search,
      type: types.SEARCH_CHANNELS_SUCCESS,
    });
  };
}

export function setActiveChannelAction(channel: IChannel) {
  return (dispatch: IAppDispatch) => {
    dispatch({
      channel,
      type: types.SET_ACTIVE_CHANNEL_SUCCESS,
    });
  };
}

export function updateChannelAction(channel: IChannel) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "channel",
      channel,
      method: "POST",
      path: "channel",
      type: types.UPDATE_CHANNEL_SUCCESS,
    });
  };
}
